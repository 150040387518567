<template>
    <div>
        <h2>สินค้าทั้งหมด</h2>
        <v-card class="mt-4 px-6 pt-6">
            <v-row>
              <v-col cols="12" md="4">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="datatable.brand_id"             
                  label="แบรนด์"
                  placeholder="พิมพ์เพื่อค้นหาแบรนด์"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/product/brandList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="brandAutocomplete"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                ></delay-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="datatable.category_id"             
                  label="หมวดหมู่"
                  placeholder="พิมพ์เพื่อค้นหาหมวดหมู่"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/product/categoryList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="categoryAutocomplete"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                ></delay-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="datatable.tag_id"             
                  label="แท็ก"
                  placeholder="พิมพ์เพื่อค้นหาแท็ก"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/product/tagList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="tagAutocomplete"
                  item-text="name"
                  item-value="id"
                  hide-details="auto"
                ></delay-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  v-model="datatable.search"
                  placeholder="ค้นหา ..."
                  dense
                ></v-text-field>
              </v-col>
            </v-row>            
        </v-card>
        <v-card class="mt-4">
          <div class="d-flex pa-4 flex-wrap">
            <v-btn              
              @click="deleteMultiple()"
              :disabled="datatable.selectedItems.length == 0"
            ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>        
            <v-btn  
            class="ml-0 ml-sm-2"   
            @click="$router.push({ 'name': 'product_export' })"            
            ><v-icon class="mr-2">{{ mdiExportVariant }}</v-icon>ส่งออก</v-btn>
            <v-btn  
            class="ml-0 ml-sm-2"   
            @click="$router.push({ 'name': 'product_import' })"
            v-if="$store.getters['auth/GET_ABILITY'].can('update', 'Product')"
            ><v-icon class="mr-2">{{ mdiImport }}</v-icon>นำเข้า</v-btn>
            <v-btn color="primary" class="ml-sm-auto" @click="$router.push({ 'name': 'product_create', params: { product_id: 'create' } })">เพิ่มสินค้า</v-btn>
          </div>
          <delay-datatable
            show-select
            :headers="datatable.headers"
            :url="'/product/list'"            
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
          >

          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              :value="props.value || props.indeterminate"
              v-on="on"
              :indeterminate="props.indeterminate"
              color="primary"
              :ripple="false"
            />
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">            
            <v-simple-checkbox
              v-model="isSelected"
              color="primary"
              @input="select"
              :ripple="false"
            />
          </template>
          
          <template          
            v-slot:item.images="{ item }"
          >   
            <product-image :image="item.images[0] ? item.images[0].URI : null" />                           
          </template>

          <template          
            v-slot:item.options="{ item }"
          >   
            <template v-for="option in item.options">
              {{ option }}
              <br>
            </template>
          </template>

          <template          
            v-slot:item.sku="{ item }"
          >   
            <template v-if="isArray(item.sku)">
              <template v-for="sku in item.sku">
                {{ sku }}
                <br>
              </template>
            </template>
            <template v-else>{{ item.sku }}</template>
          </template>

          <template          
            v-slot:item.barCode="{ item }"
          >   
            <template v-if="isArray(item.barCode)">
              <template v-for="barCode in item.barCode">
                {{ barCode }}
                <br>
              </template>
            </template>
            <template v-else>{{ item.barCode }}</template>
          </template>

          <template          
            v-slot:item.defaultPrice="{ item }"
          >   
            <template v-if="isArray(item.defaultPrice)">
              <template v-for="defaultPrice in item.defaultPrice">
                {{ defaultPrice }}
                <br>
              </template>
            </template>
            <template v-else>{{ item.defaultPrice }}</template>
          </template>

          <template          
            v-slot:item.discountedPrice="{ item }"
          >   
            <template v-if="isArray(item.discountedPrice)">
              <template v-for="discountedPrice in item.discountedPrice">
                {{ discountedPrice }}
                <br>
              </template>
            </template>
            <template v-else>{{ item.discountedPrice }}</template>
          </template>

          <template          
            v-slot:item.defaultCost="{ item }"
          >   
            <template v-if="isArray(item.defaultCost)">
              <template v-for="defaultCost in item.defaultCost">
                {{ defaultCost }}
                <br>
              </template>
            </template>
            <template v-else>{{ item.defaultCost }}</template>
          </template>
          
          <template          
            v-slot:item.actions="{ item }"
          >   
            <v-btn
              icon
              @click="$router.push({name: 'product_create', params: { product_id: item.id }})"
            ><v-icon>{{ $store.getters['auth/GET_ABILITY'].can('update', 'Product') ? mdiPlaylistEdit : mdiEyeOutline }}</v-icon></v-btn>
            <v-btn
              icon
              @click="deleteSingle(item.id, item.name)"
              :disabled="!$store.getters['auth/GET_ABILITY'].can('delete', 'Product')"
            ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>            
          </template>
          </delay-datatable>
        </v-card>        
    </div>
</template>
<script>
import { asyncDelete } from '@/helpers/asyncAxios'
import { mdiDeleteOutline, mdiExportVariant, mdiEyeOutline, mdiImport, mdiPlaylistEdit } from '@mdi/js'
export default {
  data() {
    return {
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        selectedItems: [],
        brand_id: null,
        category_id: null,
        tag_id: null,
        search: null,
        headers: [
          {
            text: 'รูป',
            value: 'images',
            sortable: false,
          },
          {
            text: 'ชื่อสินค้า',
            value: 'name',
          },
          {
            text: 'ตัวเลือกสินค้า',
            value: 'options',
          },
          {
            text: 'sku',
            value: 'sku',
          },
          {
            text: 'บาร์โคด',
            value: 'barCode',
          },
          {
            text: 'ราคา',
            value: 'defaultPrice',
          },
          {
            text: 'ราคาลด',
            value: 'discountedPrice',
          },
          {
            text: 'ต้นทุน',
            value: 'defaultCost',
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            align: 'center',
          },
        ],
      },
      mdiDeleteOutline,
      mdiPlaylistEdit,
      mdiEyeOutline,
      mdiExportVariant,
      mdiImport,
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        brand_id: this.datatable.brand_id,
        category_id: this.datatable.category_id,
        tag_id: this.datatable.tag_id,
        search: this.datatable.search,
      }
    },
  },
  methods: {
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    isArray(data) {
      return Array.isArray(data)
    },
    async deleteMultiple() {
      const productNames = this.datatable.selectedItems.map(item => item.name).join(', ')
      const productIds = this.datatable.selectedItems.map(item => item.id)

      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + productNames + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/product', { items: productIds })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
    async deleteSingle(id, name) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + name + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/product', { items: [id] })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
    },
  },
}
</script>